.builder-design {
  h2 {
    margin: 0 0 16px 0;
  }
}

.builder-design-logo {
  margin: 0 0 16px 0;
  flex-grow: 0;
}

.builder-design-color {
  margin: 0 0 16px 0;
  display: flex;
  align-items: center;

  > span {
    margin: 0 8px 0 0;
  }

  img {
    max-height: 32px;
    display: inline-block;
  }
}

.builder-design-theme {
  display: flex;
  margin: 0 0 16px 0;
  gap: 16px;
  flex-wrap: wrap;

  > * {
    width: calc(50% - 8px);
    padding: 0;
  }

  span {
    display: block;
    text-align: center;
  }

  img {
    display: block;
    text-align: center;
    width: 100%;
  }
}
