.option-list {
  display: flex;

  > * {
    flex: 1;
    text-align: center;
  }
}

.modal-preview {
  width: 100%;

  iframe {
    border: 1px solid #f4f4f4;
    width: 100%;
    height: 600px;
  }
}

.template-list {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  margin-bottom: 16px;

  > .ant-card {
    width: calc((100% - 3 * 16px) / 4);
    display: flex;
    flex-direction: column;

    .ant-card-body {
      flex: 1
    }
  }

  @media (max-width: 960px) {
    > .ant-card {
      width: calc((100% - 16px) / 2);
    }
  }
}
