.facebook-social-media-preview {
  width: 470px;
  border: 1px solid #e9eaed;
  margin: 0;
  box-shadow: 0 0 10px rgba(0, 0, 0, .1);

  .social-media-image {
    width: 472px;
    height: 236px;

    img {
      display: block;
      width: 100%;
    }
  }

  .social-media-wrapper {
    padding: 8px;
  }

  .social-media-title {
    font-size: 18px;
    line-height: 22px;
    font-family: Georgia, 'lucida grande', tahoma, verdana, arial, sans-serif;
    font-weight: 500;
    margin-top: 2px;
    margin-bottom: 7px;
    max-height: 110px;
    overflow: hidden;
    word-wrap: break-word;
  }

  .social-media-link {
    padding-top: 9px;
    padding-bottom: 1px;
    font-size: 12px;
    line-height: 16px;
    text-transform: uppercase;
    color: #9197a3;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .social-media-description {
    font-size: 12px;
    line-height: 16px;
    max-height: 80px;
    overflow: hidden;
    word-wrap: break-word;
    color: #141823;
    margin-bottom: 2px;
  }
}

.twitter-social-media-preview {
  width: 470px;
  border: 1px solid #e9eaed;
  margin: 0;
  box-shadow: 0 0 10px rgba(0, 0, 0, .1);
  display: flex;

  .social-media-image {
    width: 188px;
    height: 125px;

    img {
      display: block;
      width: 100%;
    }
  }

  .social-media-wrapper {
    padding: 8px;
  }

  .social-media-title {
    font-size: 18px;
    line-height: 22px;
    font-family: Georgia, 'lucida grande', tahoma, verdana, arial, sans-serif;
    font-weight: 500;
    margin-top: 2px;
    margin-bottom: 7px;
    max-height: 110px;
    overflow: hidden;
    word-wrap: break-word;
  }

  .social-media-link {
    padding-top: 9px;
    padding-bottom: 1px;
    font-size: 12px;
    line-height: 16px;
    color: #9197a3;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .social-media-description {
    font-size: 12px;
    line-height: 16px;
    max-height: 80px;
    overflow: hidden;
    word-wrap: break-word;
    color: #141823;
    margin-bottom: 2px;
  }
}
