.ql-editor {
  min-height: 400px;
  font-size: 16px;

  p {
    margin: 0 0 16px 0;
  }

  ul {
    margin: 0 0 16px 0;
  }
}
