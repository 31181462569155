.page {
  padding: 16px 48px 0 48px;

  @media (max-width: 720px) {
    padding: 16px 16px 0 16px;
  }
}

.page-action {
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0 0 16px 0;
  gap: 16px;
}

.page-title {
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0 0 16px 0;
  gap: 16px;

  h1 {
    margin: 0 0 0 0;
    font-size: 24px;

    img {
      height: 22px;
      display: inline-block;
      margin: 0 16px 0 0;
    }
  }
}

.layout {
  min-height: 100vh;

  header {
    padding: 0 48px;
    @media (max-width: 720px) {
      padding: 16px 16px 0 16px;
    }
  }
}

.logo {
  height: 34px;
  margin: 0 16px 0 0;

  img {
    height: 34px;
    display: block;
  }
}
