.editor-block-create {
  display: grid;
  grid-template-areas:
    "Category Component Component"
    "Category Component Component"
    "Category Pagination Pagination";
  grid-gap: 16px;
  grid-template-columns: 200px 1fr 1fr;
  grid-template-rows: 1fr 1fr 32px;
  height: 100%;

  .block-create-category {
    grid-area: Category;
  }

  .block-create-component {
    grid-area: Component;
  }

  .block-create-pagination {
    grid-area: Pagination;
  }
}

.editor-block-create {
  .block-create-category {
    width: 200px;
    height: 100%;
    overflow: auto;

    > div {
      cursor: pointer;
      background: #f4f4f4;
      padding: 8px 16px;
      margin: 0 0 4px 0;

      &:hover {
        background: #888888;
        color: #ffffff;
      }

      &.block-create-category-active {
        background: #1680c2;
        color: #ffffff;
      }

      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  .block-create-component {
    flex: 1;
    height: 100%;
    overflow: auto;
    display: flex;
    gap: 8px;
    flex-direction: column;
  }

  .template-wrapper {
    position: relative;
    margin: 0 0 16px;
  }

  .template {
    border: 1px solid #f4f4f4;
    cursor: pointer;
    position: relative;
    zoom: 0.5;

    &:hover {
      transition: border-color 0.6s linear;
      border-color: #1680c2;
    }
  }

  .template-select {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    color: #ffffff;
    align-items: center;
    justify-content: center;
    z-index: 2;
    display: none;
    border-radius: 6px;
  }

  .template-wrapper:hover {
    .template-select {
      display: flex;
    }
  }

  .template-loading {
    width: 100%;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
  }
}
