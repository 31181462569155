.image-scroll {
  height: 200px;
  overflow: hidden;
  border: 1px solid #f0f0f0;
  border-radius: 8px 8px 0 0;
  position: relative;

  img {
    display: block;
    width: 100%;
    position: absolute;
    top: 0;
    transition: all 5s ease;
  }
}
