@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind variants;
@tailwind screens;

@import 'material-symbols';
@import '@fontsource/inter';
@import '@fontsource/roboto';
@import '@fontsource/poppins';
@import '@fontsource/lato';
@import '@fontsource-variable/open-sans';

html, body {
  min-height: 100vh;
  padding: 0;
  margin: 0;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  line-height: 1.3;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.prose {
  width: 100%;
  max-width: max-content;
}

#builder {
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  > header {
    padding: 16px 48px;
    background: #0a0a0a;
    display: flex;
    align-items: center;
  }

  > main {
    flex: 1
  }

  > footer {
    padding: 32px 48px;
    background: #0a0a0a;
    color: #f4f4f4;
    text-align: center;

    a {
      text-decoration: underline;
      color: #f4f4f4;
    }
  }
}

.ant-btn {
  background-color: #f4f4f4;
}

.ant-btn-primary {
  background-color: #106fff !important;
}

.material-symbols-outlined {
  font-variation-settings: 'FILL' 0,
  'wght' 400,
  'GRAD' 0,
  'opsz' 24
}
