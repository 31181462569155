.builder-deploy {
  padding: 100px;
  text-align: center;

  h2 {
    margin: 0 0 16px 0;
  }

  h3 {
    margin: 0 0 16px 0;
  }
}
