.builder {
  gap: 16px;
  height: calc(100vh - 142px);
  display: flex;
}

.builder-design {
  width: 300px;
  height: 100%;
  overflow: auto;
}

.builder-brand {
  > div {
    height: calc(100vh - 200px);
    overflow: auto;
    flex: 1;
  }

  label {
    margin: 0 0 8px 0;
    display: block;
    font-weight: bold;
  }

  .ant-select {
    margin: 0 0 16px 0;
    display: block;
  }

  input {
    margin: 0 0 16px 0;
    display: block;
  }

  textarea {
    margin: 0 0 16px 0;
    display: block;
  }
}

.builder-preview {
  flex: 1;
  height: 100%;
  overflow: auto;
}
