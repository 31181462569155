.editor-button-wrapper {
  margin: 0;
}

.editor-button {
  display: flex;
  gap: 8px;
  background: #f4f4f4;
  padding: 8px 16px;
  border-top: 1px solid #888888;
  border-bottom: 1px solid #888888;
}

// Layout
.editor {
  display: flex;
  flex-direction: column;
  gap: 0;
  padding: 0;
  margin: 0;
  min-height: 100vh;

  .part-watermark {
    display: flex;
    justify-content: center;
    align-content: center;

    .block-create {
      position: absolute;
      top: 50%;
    }
  }

  .part-header.part-watermark {
    border-bottom: 1px solid #ccc;
    min-height: 200px;
  }

  .part-main {
    flex: 1;
  }

  .part-footer.part-watermark {
    border-top: 1px solid #ccc;
    min-height: 200px;
  }

}

// Block Header
.block-list {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

// Block Header
.block-header {
  border-top: 1px dashed #888888;
  border-bottom: 1px dashed #888888;
  padding: 0;
  text-align: center;
  background: #ffffff;
}

.block-footer {
  padding: 0;
  text-align: center;
  background: #ffffff;
}

.block-create {
  display: block;
  height: 0;
  padding: 0;
  text-align: center;
  background: #ffffff;
  cursor: pointer;
  transition: all 0.1s ease;
  position: relative;
  z-index: 3;

  button {
    position: relative;
    top: -12px;
  }
}

// Block
.block {
  position: relative;
  border-bottom: 1px dashed #888888;
  padding: 0;

  .block-action {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 2;
    display: none;
    height: 1px;
    justify-content: space-between;
    gap: 2px;
    padding: 8px 16px;
  }

  .block-description {
    position: absolute;
    top: 32px;
    left: 0;
    width: 100%;
    z-index: 2;
    display: none;
    height: 1px;
    justify-content: space-between;
    gap: 2px;
    padding: 4px 8px;
    color: #fff;
    text-shadow: 1px 1px 1px #000000;
  }

  .block-component {
    position: relative;
    display: block;
    height: auto;
    font-size: 18px;
    z-index: 1;
  }

  &:hover {
    .block-action,
    .block-description {
      display: flex;
    }
  }

  &:hover + .block-create {
    display: block;
  }
}

.rjsf {
  fieldset {
    border: 1px solid #cccccc;
    padding: 16px;
    border-radius: 8px;
  }

  fieldset#root {
    border: none;
    padding: 0;
  }
}

@media print {
  .editor-breadcrumb,
  .editor-button,
  .block-action,
  .block-create,
  .block-description {
    display: none !important;
  }

  .block {
    break-inside: avoid;
  }
}

.editor-frame-wrapper {
  background: #000;
}

.editor-frame {
  background: #fff;
  height: calc(100vh - 96px);
  display: block;
  width: 100%;
  margin: auto;

  &.editor-frame-max-w-screen-sm {
    max-width: 412px;
  }

  &.editor-frame-max-w-screen-md {
    max-width: 820px;
  }

  &.editor-frame-max-w-screen-lg {
    max-width: 1024px;
  }
}
