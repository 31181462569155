.seo-preview {
  box-shadow: 0 0 10px rgba(0, 0, 0, .1);
  font-family: arial, helvetica, sans-serif;
  max-width: 470px;
  width: 100%;
  border: 1px solid #e9eaed;
  margin: 0;
  padding: 12px;
  box-sizing: border-box;

  .seo-title {
    font-size: 20px;
    line-height: 28px;
    color: #1a0dab;
  }

  .seo-link {
    font-size: 14px;
    color: #004d18;
    padding: 4px 0;
  }

  .seo-description {
    padding-bottom: 3px;
    font-size: 13px;
    line-height: 17px;
    color: #000;
  }
}
