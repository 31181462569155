.bg-color {
  border: 1px solid #f4f4f4;
  padding: 16px;
  margin: 0 0 4px;
  cursor: pointer;

  .checked {
    border-color: #056eaf;
  }
}

.bg-pattern {
  border: 1px solid #f4f4f4;
  cursor: pointer;

  .checked {
    border-color: #056eaf;
  }
}

.bg-showoff {
  border: 1px solid #056eaf;
  height: 200px;
  padding: 16px;
}
