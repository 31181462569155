.rjsf {
  display: block;
  height: calc(100vh - 200px);
  overflow-y: auto;
  overflow-x: hidden;
}

.rjsf-save {
  position: fixed;
  bottom: 0;
  left: 0;
  background: #f4f4f4;
  border-top: 1px solid #ccc;
  padding: 16px 32px;
  width: 80%;
  z-index: 2;
}
