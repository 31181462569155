ul.menu {
  list-style: none;
  flex: 1;
  display: flex;
  width: 100%;
  gap: 16px;

  li {
    a {
      color: #ffffff;
      padding: 8px;
    }
  }

  li.active {
    a {
      border-bottom: 1px solid #ffffff;
    }
  }
}
