.builder-type {
  padding: 100px;

  label {
    margin: 0 0 16px 0;
    display: block;
    font-weight: bold;
    text-align: center;
    font-size: 24px;
  }

  input {
    margin: 0 0 16px 0;
    display: block;
  }
}

.builder-type-selector {
  width: 100%;
  margin: 0 0 16px 0;
  display: block;
}
