.block-switch {
  .template-wrapper {
    position: relative;
    margin: 0 0 16px;
  }

  .template {
    border: 1px solid #f4f4f4;
    cursor: pointer;
    position: relative;
    // overflow-x: hidden;
    zoom: 0.5;


    &:hover {
      transition: border-color 0.6s linear;
      border-color: #1680c2;
    }
  }

  .template-select {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    color: #ffffff;
    align-items: center;
    justify-content: center;
    z-index: 2;
    display: none;
    border-radius: 6px;
  }

  .template-wrapper:hover {
    .template-select {
      display: flex;
    }
  }
}
