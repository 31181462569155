.browser {
  border-radius: 4px;
  background: #f4f4f4;
  display: flex;
  flex-direction: column;
  user-select: none;
  height: 100%;

  .browser-bar {
    background: #666;
    display: flex;
    padding: 8px 0 0 0;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;

    .browser-button {
      display: flex;
      gap: 8px;
      padding: 8px 16px;

      div {
        background: #f4f4f4;
        border-radius: 50%;
        width: 12px;
        height: 12px;
        display: block;
        position: relative;
        top: 0;
      }
    }

    .browser-title {
      background: #f4f4f4;
      padding: 8px 32px 8px 16px;
      min-width: 200px;
      max-width: 400px;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      position: relative;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: flex;
      align-items: center;

      img {
        height: 18px;
        display: inline-block;
        margin: 0 8px 0 0;
      }

      .browser-title-close {
        position: absolute;
        right: 10px;
        top: 8px;
      }
    }
  }

  .browser-url {
    background: #f4f4f4;
    display: flex;
    padding: 8px;
    border-left: 1px solid #888888;
    border-right: 1px solid #888888;

    .browser-link {
      padding: 8px 16px;
      background: #ffffff;
      border: 1px solid #cccccc;
      border-radius: 8px;
      width: 100%;
    }
  }

  .browser-content {
    position: relative;
    border: 1px solid #888888;
    overflow: auto;
    flex: 1;
  }
}
