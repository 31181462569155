.page-list {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  margin-bottom: 16px;

  > .ant-card {
    width: calc((100% - 3 * 16px) / 4);
    display: flex;
    flex-direction: column;

    .ant-card-body {
      flex: 1
    }
  }

  @media (max-width: 1200px) {
    > .ant-card {
      width: calc((100% - 2 * 16px) / 3);
    }
  }

  @media (max-width: 960px) {
    > .ant-card {
      width: calc((100% - 16px) / 2);
    }
  }

  @media (max-width: 600px) {
    > .ant-card {
      width: 100%;
    }
  }

  .page-favicon {
    height: 16px;
    margin: 0 8px 0 0;
    position: relative;
    top: 2px;
  }

  .page-logo {
    height: 32px;
    margin: 0 8px 0 0;
    position: relative;
    top: 2px;
  }
}
